export interface BillDetail {
  bill_id: string,
  bill_licenseplate: string,
  bill_token: string,
  bill_lot_code: string,
  bill_amount: number,
  // for parking
  bill_parking_hour: number,
  bill_rate: number,
  payment_status: BillPaymentStatus,
  operator_code: string,
  lot_entering_time:string,
  // for rent
  bill_lot_name: string,
  rent_comment: string,
  user_id: string,
  name: string,
  phone_number: string,
  common_payment_info:any,
  // for city
  bill_detail: any,
  error_message: string,
  lot_allow_exit_time: string,
}

export enum BillPaymentStatus{
  UnPaid,
  Paid,
  NotAvailable,
}