import { useContext } from 'react';

import Button from 'components/Button'
import { GlobalContext } from 'contexts/GlobalContext';
import { StepContext } from 'contexts/StepContext';
import { BillDetail, BillPaymentStatus } from 'types';

interface Props extends BillDetail {
  // userColumn: string;
}

const SearchRentConfirm = ({
  bill_licenseplate,
  bill_lot_name,
  rent_comment,
  bill_amount,
  user_id,
  name,
  phone_number,
  // userColumn,
  payment_status,
}: Props) => {
  const {
    removeBillDetail,
  } = useContext(GlobalContext);
  const { nextStep } = useContext(StepContext);

  const goToBack = () => {
    removeBillDetail();
  };

  return (
    <div className="p-4" >

      <h2 className="mb-5 leading-5 text-center">請確認資料是否正確</h2>

      <div className='w-[145px] mx-auto text-gray-label my-14 text-[12px]'>
        {/* <div className='mb-5'>
          {userColumn}：{user_id}
        </div> */}
        <div className='mb-5'>
          姓名：{name}
        </div>
        <div className='mb-5'>
          手機號碼：{phone_number}
        </div>
        <div className='mb-5'>
          車牌號碼：{bill_licenseplate}
        </div>
        <div className='mb-5'>
          申辦之停車場：<br />
          {/* {bill_lot_name} */}
          {rent_comment}
        </div>
        <div className='mb-5'>
          應繳金額：{bill_amount}
        </div>
        <div>
          繳費狀態 ：{payment_status === BillPaymentStatus.UnPaid ? '未繳費' : '已繳費'}
        </div>
      </div>
      <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 m-auto '>
        {payment_status === BillPaymentStatus.UnPaid && (<div className="w-full text-center">
          <Button title="下一步" onClick={() => nextStep()} />
        </div>)}
        <div className='md:max-w-[826px] max-w-[260px] mx-auto absolute top-1/2 left-0' style={{ transform: "translateY(-50%)" }}>
          <i className="fa-solid fa-chevron-left cursor-pointer text-primary" onClick={goToBack} ></i>
        </div>
      </div>
    </div>
  );
}
export default SearchRentConfirm;