import { createContext } from 'react';
import { BillDetail, ParkingInformation } from 'types';

interface globalContextInterface {
  isLoading: boolean,
  setIsLoading: (arg: boolean) => void,
  removeBillDetail: () => void,
  updateBillDetail: (args: Partial<BillDetail>) => void,
  billDetail: BillDetail,
  parkingInformation: ParkingInformation,
  fetchInformation: ({ lot_code, operator_code, area_id }: { lot_code?: string, operator_code?: string, area_id?: string }) => void,
  isAppleDevice: boolean,
  setIsAppleDevice: (arg: boolean) => void,
}

export const GlobalContext = createContext<globalContextInterface>({
  isLoading: false,
  setIsLoading: () => { },
  removeBillDetail: () => { },
  billDetail: {
    bill_id: '',
    bill_licenseplate: '',
    bill_parking_hour: 0,
    bill_rate: 0,
    bill_amount: 0,
    bill_lot_code: '',
    bill_token: '',
    payment_status: 0,
    user_id: '',
    name: '',
    phone_number: '',
    operator_code: '',
    bill_lot_name: '',
    rent_comment: '',
    lot_entering_time: '',
    common_payment_info: {},
    bill_detail: [],
    error_message: '',
    lot_allow_exit_time: '',
  },
  updateBillDetail: () => { },
  parkingInformation: {
    lot_name: '',
    lot_phone: '',
    operator: '',
    index_page_note: '',
    after_paying_page_note: '',
    userid_column_name: '',
    app_id: 0,
    app_key: '',
    merchant_identifier: '',
    is_line_pay: 0,
    is_apple_pay: 0,
    is_taiwan_pay: 0,
    area_id: '',
    area_name: '',
    area_phone: '',
    find_car_url: '',
    is_tax: 0,
  },
  fetchInformation: () => { },
  isAppleDevice: false,
  setIsAppleDevice: () => { },
});

export default GlobalContext;