import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import GlobalContext from 'contexts/GlobalContext';
import { ReactComponent as Arrow } from 'images/arrow.svg'
import useOnClickOutside from 'hooks/useClickOutside';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import InputField from 'components/InputField'


export interface Option {
  key: string,
  title: string,
  inputLabel: string,
  value?: string,
}

interface Props {
  onChange?: (args: Option) => void,
  clearErrors: (args?: string[]) => void,
  setValue: (a: string, b: string) => void,
  watch: (arg: string[]) => [],
  getValues: (payload?: string | string[]) => Object | string
  errors: {
    [x: string]: any;
  }
  defaultValue?: string,
  register: UseFormRegister<FieldValues>,
  carrer_id_number: string
}


const SelectInput = ({ onChange, defaultValue, register, errors, clearErrors, carrer_id_number, setValue, watch }: Props) => {

  const { billDetail } = useContext(GlobalContext);

  const options = [
    {
      key: 'carrier_id',
      title: '手機條碼載具',
      inputLabel: '手機條碼載具',
      value: carrer_id_number
    },
    {
      key: 'tax_id',
      title: '統一編號-寄發電子憑證至信箱',
      inputLabel: '統一編號',
    },
    {
      key: 'donate-25885',
      title: '捐贈發票-愛心碼25885-伊甸基金會',
      inputLabel: '捐贈發票',
      value: '愛心碼25885-伊甸基金會'
    },
    {
      key: 'donate-919',
      title: '捐贈發票-愛心碼919-創世基金會',
      inputLabel: '捐贈發票',
      value: '愛心碼919-創世基金會'
    },
    {
      key: 'donate-8585',
      title: '捐贈發票-愛心碼8585-家扶基金會',
      inputLabel: '捐贈發票',
      value: '愛心碼8585-家扶基金會'
    }
  ]

  const rootRef = useRef<HTMLDivElement>(null)
  const [selectOpen, setSelectOpen] = useState(false)
  // const [prevSelected, setPrevSelected] = useState('')
  const [optionList, setOptionList] = useState(options)

  const [selectedOption, setSelectedOption] = useState<Option>(
    { key: '', title: '', inputLabel: '', value: '' }
  )

  const receiptFields = watch(['carrier_id', 'tax_id', 'donate-25885', 'donate-919', 'donate-8585'])

  useEffect(() => {
    const hasAnyValue = receiptFields.some(Boolean) || selectedOption.key === 'donate-25885' || selectedOption.key === 'donate-919' || selectedOption.key === 'donate-8585' || selectedOption.key === 'carrier_id'

    if (errors.receiptMethodIsRequire && hasAnyValue) {
      clearErrors([
        'receiptMethodIsRequire'
      ])
    }
  }, [errors, clearErrors, receiptFields, selectedOption])

  useEffect(() => {

    const defaultReceiptOption: Option | undefined = options.find(option => option.key === defaultValue)

    if (!!defaultValue && !defaultReceiptOption?.key) {
      setOptionList([{
        key: defaultValue,
        title: defaultValue,
        inputLabel: defaultValue,
        value: carrer_id_number
      }, ...options])
    } else {
      // 無統編：路外場域(pay)單號0開頭
      if (billDetail.bill_id.startsWith('0')) {
        const filteredOptions = options.filter(option => option.key !== 'tax_id');
        setOptionList(filteredOptions);
      } else {
        setOptionList(options);
      }
      // the previous input value has been store in parent component
      //if there was a value previously, reset it.
      if (!defaultReceiptOption?.key) return
      setSelectedOption(defaultReceiptOption)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, setValue, carrer_id_number])

  const onSelectClick = () => {
    setSelectOpen(!selectOpen)
  }

  const handleClickOutside = () => {
    setSelectOpen(false)
  }

  const onSelectChange = useCallback(
    (value: string) => () => {

      clearErrors([
        'carrier_id',
        'tax_id',
        'donate-25885',
        'donate-919',
        'donate-8585'
      ])

      setValue(selectedOption.key, '')
      const selectingOption = optionList.find(({ key }) => key === value)

      if (!selectingOption) return

      if (selectingOption.key === 'donate-919') {
        setValue(selectingOption.key, selectingOption.value || '')
        setValue('donate-8585', '')
        setValue('donate-25885', '')
      } else if (selectingOption.key === 'donate-8585') {
        setValue(selectingOption.key, selectingOption.value || '')
        setValue('donate-919', '')
        setValue('donate-25885', '')
      } else if (selectingOption.key === 'donate-25885') {
        setValue(selectingOption.key, selectingOption.value || '')
        setValue('donate-919', '')
        setValue('donate-8585', '')
      } else if (selectingOption.key === 'carrier_id') {
        setValue(selectingOption.key, carrer_id_number || '')
      }


      onChange && onChange(selectingOption)
      setSelectedOption(selectingOption)
      setSelectOpen(false)
    }, [clearErrors, setValue, selectedOption.key, optionList, onChange, carrer_id_number])

  useOnClickOutside(rootRef, handleClickOutside)

  // 統編規則
  const checkTaxNumber = (value: any) => {
    const cx = [1, 2, 1, 2, 1, 2, 4, 1];
    if (value.length !== 8) {
      return '統一編號錯誤';
    }
    let sum = 0;
    const cnum = value.split('');

    const cc = (num: any) => {
      let total = num;
      if (total > 9) {
        const s = total.toString();
        total = parseInt(s[0]) + parseInt(s[1]);
      }
      return total;
    };

    cnum.forEach((item: any, index: any) => {
      sum += cc(item * cx[index]);
    });

    if (sum % 10 === 0) {
      return true;
    } else if (cnum[6] === '7' && (sum + 1) % 10 === 0) {
      return true;
    } else {
      return '統一編號錯誤';
    }
  };


  return <div className='relative' ref={rootRef}>
    <div className="inline-flex items-center min-w-[205px]">

      <button
        onClick={onSelectClick}
        id="dropdownDefault"
        className="flex-1 w-max px-1 py-[2px] text-gray-label bg-gray-light focus:outline-none text-sm text-center inline-flex items-center" type="button">

        <Arrow />
        <span className="ml-[2px] text-[12px] leading-[15px]"> {selectedOption.inputLabel || '請選擇電子發票存入方式'}  </span>
      </button>

      {!!selectedOption.title && <span className='text-gray-label'>：</span>}

      <>
        {
          selectedOption.key === 'carrier_id' && <InputField
            {...register(
              'carrier_id', {
              setValueAs: value => value.trim(),
              required: '請輸入手機載具號碼',
              pattern: {
                value: /^\/[0-9A-Z.+-]{7}$/i,
                message: '手機載具格式錯誤',
              }
            })}
            noLabel
            required
            defaultValue={carrer_id_number}
            placeholder={'請輸入手機條碼載具'}
            error={errors['carrier_id']}
          />
        }

        {
          selectedOption.key === 'tax_id' && <InputField
            {...register('tax_id', {
              required: '請輸入統一編號',
              validate: checkTaxNumber
              // pattern: {
              //   value: /^[0-9]{8}$/i,
              //   message: '統一編號格式錯誤'
              // }
            })}
            noLabel
            required
            placeholder={'請輸入統一編號'}
            error={errors.tax_id}
          />
        }

        {
          selectedOption.key === 'donate-25885' && <InputField
            {...register('donate-25885')}
            noLabel
            required
            value='愛心碼25885-伊甸基金會'
            defaultValue='愛心碼25885-伊甸基金會'
            placeholder={'愛心碼25885-伊甸基金會'}
            error={errors['donate-25885']}
            inputClassName="placeholder:text-gray-label disabled:text-gray-label"
          />
        }

        {
          selectedOption.key === 'donate-919' && <InputField
            {...register('donate-919')}
            noLabel
            required
            value='愛心碼919-創世基金會'
            defaultValue='愛心碼919-創世基金會'
            placeholder={'愛心碼919-創世基金會'}
            error={errors['donate-919']}
            inputClassName="placeholder:text-gray-label disabled:text-gray-label"
          />
        }

        {
          selectedOption.key === 'donate-8585' && <InputField
            {...register('donate-8585')}
            noLabel
            required
            defaultValue='愛心碼8585-家扶基金會'
            value='愛心碼8585-家扶基金會'
            placeholder={'愛心碼8585-家扶基金會'}
            error={errors['donate-8585']}
            inputClassName="placeholder:text-gray-label disabled:text-gray-label"
          />
        }
      </>

    </div>

    {
      selectOpen && <ul className={`
        absolute z-10 pt-1
        pb-1 bg-white top-6
        left-0 shadow-selectOption w-full text-[11px] text-gray-label`} >
        {
          optionList.map(
            option => {
              return <li
                key={option.key}
                className='cursor-pointer hover:bg-gray-light pl-4 mb-[2px] text-gray-label '
                onClick={onSelectChange(option.key)}>
                {option.title}
              </li>
            }
          )
        }


      </ul>
    }

  </div >;


}
export default SelectInput;